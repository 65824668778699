import React, { useState, useContext, useEffect } from "react"
import { Link, navigate } from "gatsby"

import VisibilityOffIcon from "@material-ui/icons/VisibilityOff"
import VisibilityIcon from "@material-ui/icons/Visibility"
import SparkleLogo from "../assets/images/mascot_welcome_logo.png"
import bayadDirectLogo from "../assets/svg/bayaddirect.jpg"
import CircularProgress from "@material-ui/core/CircularProgress"
import Alert from "@material-ui/lab/Alert"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import { signIn } from "../api/public/auth"
import SecureLS from "secure-ls"
import { Formik, Form, useField } from "formik"
import * as yup from "yup"
import { Dialog, DialogActions, IconButton } from "@material-ui/core"
import { LoginState } from "./globalstates"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { isLoggedIn } from "../services/auth"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { Box } from "@mui/system"
import useLoggedUser from "../custom-hooks/useLoggedUser"
import { Stack } from "@mui/material"
import RegistrationPage from "./RegistrationPage"
import CollapseAlert from "./Alerts/CollapseAlert"
import ChangePassword from "./ChangePassword"

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: "3em",
  },
  submit: {
    margin: theme.spacing(1, 0, 1),
    backgroundColor: "#FFCF10",
  },
  image: {
    height: "10em",
    width: "17em",
    marginBottom: "2em",
    marginLeft: "2.5rem",
  },
  container: {
    width: "340px",
  },
  textBox: {
    fontFamily: "visby",
    marginBottom: "0.2em",
    fontSize: "0.6em",
  },
  text: {
    marginTop: "0.2em",
    marginBottom: "0.3em",
    fontFamily: "visby",
    fontSize: "0.7em",
    paddingTop: "0.1em",
    paddingBottom: "0.1em ",
  },
}))

const buttonStyle = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(0, 0, 0),
    background: "#ffcf10",
    border: 0,
    borderRadius: 3,
    color: "black",
    height: 35,
    padding: "0 30px",
  },
}))

const LoginPage = ({
  hideLogo = false,
  showBackButton = false,
  overrideBackFunction = false,
  backFunction = () => { },
}) => {
  const [isLoggin, setisLoggin] = useContext(LoginState)

  const classes = useStyles()

  const buttonClass = buttonStyle()

  const { setUser } = useLoggedUser()


  const [alertState, setAlertState] = useState({
    open: false,
    severity: "success",
    message: "We are trying to tell you something"
  })

  const [showPassword, setShowPassword] = useState(false)

  const [showRegistration, setShowRegistration] = useState(false)

  const [changePassword, setChangePassword] = useState(false)

  const [tempUserData, setTempUserData] = useState({})

  const loginUser = (data) => {

    console.log(data)

    setUser({ userData: data })

    setisLoggin(true)

  }



  useEffect(() => {
    setisLoggin(isLoggedIn())
  }, [])

  const validationSchema = yup.object({
    email: yup.string().email("Invalid email"),
    password: yup.string(),
  })

  const CustomTextField = ({
    id,
    type,
    label,
    placeholder,
    InputProps,
    ...props
  }) => {
    const [field, meta] = useField(props)
    const errorText = meta.error && meta.touched ? meta.error : ""
    return (
      <TextField
        id={id}
        label={label}
        variant="outlined"
        required
        fullWidth
        type={type}
        placeholder={placeholder}
        {...field}
        helperText={errorText}
        error={!!errorText}
        InputProps={InputProps}
        className={classes.textBox}
      />
    )
  }

  return (
    <Box>
      {
        changePassword &&
        <ChangePassword
          onSuccess={(e) => {
            loginUser(e)
          }}
          token={tempUserData?.token}
        />
      }
      {showRegistration && (
        <RegistrationPage
          overrideBackFunction={true}
          backFunction={() => {
            setShowRegistration(false)
          }}
        />
      )}
      {!showRegistration && !changePassword && (
        <Box>
          {showBackButton && (
            <Box marginTop={1} marginLeft={1}>
              <IconButton
                onClick={() => {
                  overrideBackFunction ? backFunction() : navigate(-1)
                }}
              >
                <ArrowBackIcon />
              </IconButton>
            </Box>
          )}
          <Stack direction={"row"} justifyContent={"center"}>
            <Box
              maxWidth="300px"
            // eslint-disable-next-line react/jsx-no-duplicate-props
            >
              <div className={hideLogo ? classes.paper : ""}>
                {!hideLogo ? (
                  <Stack direction={"row"} justifyContent={"center"}>
                    <LazyLoadImage
                      src={bayadDirectLogo}
                      alt="sparkle-logo"
                      effect={"blur"}
                      width={"100%"}
                    />
                  </Stack>
                ) : (
                  <></>
                )}
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={async (data) => {
                    try {

                      let result = await signIn(data.email, data.password)

                      console.log(result)

                      setTempUserData(result)

                      if (result?.user?.isInitPassword) {
                        setChangePassword(true)

                        return
                      }

                      loginUser(result)



                    } catch (error) {

                      setAlertState((prevState) => (
                        {
                          ...prevState,
                          open: true,
                          severity: "error",
                          message: JSON.parse(error.message).error
                        }
                      ))

                    }

                  }}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <Grid container spacing={1} alignItems="flex-end">
                        <Grid item xs={12}>
                          <CustomTextField id="emailField" label="Email" name="email" />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} alignItems="flex-end" margin>
                        <Grid item xs={12} className={classes.textBox}>
                          <CustomTextField
                            id="passwordField"
                            label="Password"
                            name="password"
                            type={showPassword ? "text" : "password"}
                            InputProps={{
                              endAdornment: (
                                <IconButton
                                  onClick={() => {
                                    setShowPassword(!showPassword)
                                  }}
                                >
                                  {showPassword ? (
                                    <VisibilityIcon />
                                  ) : (
                                    <VisibilityOffIcon />
                                  )}
                                </IconButton>
                              ),
                            }}
                          />
                        </Grid>
                      </Grid>
                      <CollapseAlert
                        open={alertState.open}
                        severity={alertState.severity}
                        message={alertState.message}
                        onClose={() => {
                          setAlertState((prevState) => ({ ...prevState, open: false }))
                        }}
                      />
                      <Grid>
                        <Grid item xs={12} className={classes.text}>
                          Forgot password? {""}
                          <Link to="/reset" variant="body2">
                            Click here
                          </Link>
                        </Grid>
                      </Grid>
                      <Button
                        id="loginButton"
                        disabled={isSubmitting}
                        type="submit"
                        fullWidth
                        className={classes.submit}
                      >
                        {isSubmitting ? <CircularProgress /> : "Login"}
                      </Button>
                    </Form>
                  )}
                </Formik>
              </div>
            </Box>
          </Stack>
        </Box>
      )}

    </Box>
  )
}

export default LoginPage
